$primary-color: #0583bf;
$text-on-primary-color: #ffffff;
$secondary-color: #052a3f;
$text-on-secondary-color: #ffffff;
$light-background-color: #ffffff;
$light-accent-background-color: #fafafa;
$text-on-light-background-color: #000000;
$dark-background-color: #000000;
$text-on-dark-background-color: #ffffff;
$dark-accent-background-color: #292929;

.accent-bg {
  background-color: $light-accent-background-color;
  color: $text-on-light-background-color;
}
