// page setup
sss-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#footer {
  margin-top: auto;
}

// components
.page-section {
  display: flex;
  justify-content: center;
  padding: 5rem;
  h1 {
    font-weight: 900;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 160rem; // ~1600px
  }
}

.v-scrollable {
  overflow-y: auto;
}

.alternate-rows {
  tr {
    &:nth-child(even) {
      background-color: #f7f7f7;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-radius: 3px;
  box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.25);
  background-color: white;
}

.legal-section {
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    width: 100%;
  }
}

// ~ 920px
@media (max-width: 57.5rem) {
  .page-section {
    padding: 5rem 2rem;
  }
}

// customer view layout
.customer-view-container {
  flex: 1;
  padding: 0rem 2rem;
}

.customer-view-sections {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 12rem;

  .section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 250px;
  }
}

.customer-view-actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0rem 2rem;
  margin-left: 7rem;
  margin-top: 2rem;
}
