@use 'sass:map';
@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

@use 'photoviewer';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@import './presets.scss';
@import './colors.scss';
@import './fonts.scss';
@import './forms.scss';
@import './layout.scss';
@import './util.scss';
@import './shadows.scss';
@import './custom.scss';

$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

@include mat.core();

body {
  --primary-color: #00a6f4;
  --primary-lighter-color: #b3bfcc;
  --primary-darker-color: #00a6f4;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: #00a6f4,
  lighter: #b3bfcc,
  darker: #00183b,
  200: #00a6f4,

  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #052a3f;
  --accent-lighter-color: #004c77;
  --accent-darker-color: #011b2b;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: #052a3f,
  lighter: #004c77,
  darker: #011b2b,
  200: #052a3f,

  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,

  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$typographyConfig: mat.m2-define-typography-config(
  $font-family: 'Inter',
);

$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
      foreground: $mat-light-theme-foreground,
      background: $mat-light-theme-background,
    ),
    typography: $typographyConfig,
  )
);
$darkTheme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
      foreground: $mat-dark-theme-foreground,
      background: $mat-dark-theme-background,
    ),
    typography: $typographyConfig,
  )
);

@include mat.all-component-themes($theme);
@include mat.all-component-colors($theme);
@include mtx.all-component-themes($theme);
@include mat.slide-toggle-color($theme);

.theme-alternate {
  @include mat.all-component-themes($darkTheme);
  @include mat.all-component-colors($darkTheme);
  @include mtx.all-component-themes($darkTheme);
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.mat-sort-header-content {
  text-align: start !important;
}

.mat-mdc-menu-panel {
  button {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  &.like-select {
    overflow: unset;
    .mat-mdc-menu-content {
      padding: 0;
    }

    button {
      justify-content: center;
      padding: 1rem;
      height: unset;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
      }
      &:last-child {
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
    }
  }
}

// .material-icons {
//   font-size: 24px;
//   font-family: 'Material Icons', 'Material Icons';
//   .mat-badge-content {
//     font-family: 'Roboto';
//   }
// }

$primary-color: map.get($mat-primary, 'main');
$accent-color: map.get($mat-accent, 'main');
$warn-color: map.get($mat-warn, 'main');
$foreground-color: map.get($mat-light-theme-foreground, 'base');
$background-color: map.get($mat-light-theme-background, 'background');

:root {
  --mdc-switch-selected-track-color: #00183b;
  --mdc-switch-selected-focus-track-color: #00183b;
  --mdc-switch-selected-hover-track-color: #00183b;
  --mdc-switch-unselected-pressed-track-color: #00183b;

  --mdc-switch-unselected-track-color: lightgrey;
  --mdc-switch-unselected-focus-track-color: lightgrey;
  --mdc-switch-unselected-hover-track-color: lightgrey;
  --mdc-switch-unselected-pressed-track-color: lightgrey;

  --mdc-switch-selected-icon-color: rgba(0, 0, 0, 0);
  --mdc-switch-disabled-selected-icon-color: rgba(0, 0, 0, 0);
  --mdc-switch-unselected-icon-color: rgba(0, 0, 0, 0);
  --mdc-switch-disabled-unselected-icon-color: rgba(0, 0, 0, 0);
}
