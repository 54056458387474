html {
  font-size: 62.5%;
  scroll-padding-top: 9.5rem;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  font-size: 1.6rem;
}

* {
  box-sizing: border-box;
}

h1 {
  // margin: 0.5rem 0;
}
// h1,
// h2,
// h3,
// h4,
// p {
//   margin: 0.5rem 0;
// }

.mat-mdc-menu-content {
  display: flex;
  flex-direction: column;
}

#root button[mat-flat-button] {
  min-height: fit-content;
  min-width: fit-content;
  padding: 0.5rem 1rem;
}

#root,
menu,
button[mat-flat-button] {
  mat-icon {
    font-size: 2rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }
}

#root button[mat-fab] {
  mat-icon {
    font-size: 2.5rem !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

mat-card {
  padding: 1rem 2rem;
}
