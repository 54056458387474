@import './colors.scss';

form {
  max-width: 100%;
}

.custom-form-field {
  // background-color: #ececec;
  // border-top-left-radius: 5px;
  // border-top-right-radius: 5px;
  // border-bottom: 1px solid grey;
  border-radius: 5px;
  border: solid 1px #9e9e9e;

  padding: 1rem;
  margin-bottom: 2.2rem;

  .label {
    font-size: 1.3rem;
    color: rgb(99, 99, 99);
  }

  &:hover {
    border-color: #474747;
  }
}

.flex-row-field-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  &.custom-checkbox {
    flex-wrap: nowrap;
    align-items: center;
  }

  formly-field {
    flex: 1;
    min-width: 15rem;

    &.min-width {
      flex: unset;
      min-width: fit-content;
      width: fit-content;
    }
  }

  @media (max-width: 57.5rem) {
    flex-wrap: wrap;
  }
}

.form-modal-text {
  max-width: 60rem;
  margin: 0;
}

.checkbox-list {
  mat-checkbox {
    width: 100%;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: initial !important;
  -webkit-appearance: initial !important;
}
input[type='datetime-local']::-webkit-inner-spin-button,
input[type='datetime-local']::-webkit-calendar-picker-indicator {
  display: initial !important;
  -webkit-appearance: initial !important;
}

.mat-mdc-form-field-type-checkbox {
  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
    background-color: $light-background-color !important;
    .mat-mdc-form-field-infix {
      padding: 0;
      background: $light-background-color;
    }
  }
}

.mtx-calendar-header,
.mtx-calendar-header-hours,
.mtx-calendar-header-minutes {
  width: unset !important;
}

.mtx-clock,
.mtx-calendar-table {
  font-size: 1.6rem !important;
}

.mtx-datetimepicker-toggle {
  button[mat-icon-button] {
    width: 3rem;
    height: 3rem;
    padding: 0;
  }
}
